<template>
  <div class="concept-layout">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ConceptLayout',
}
</script>
<style lang="sass" scoped>
</style>
