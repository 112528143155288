<template>
  <div class="content">
    <h1>お問い合わせ</h1>
    <form @submit.prevent="send">
      <div>
        <label for="email">メールアドレス</label>
        <input
          id="email"
          type="email"
          v-bind="email"
        >
      </div>
      <div>
        <label for="phone">電話番号</label>
        <input
          id="phone"
          type="tel"
          v-bind="tel"
        >
      </div>
      <div>
        <label for="name">氏名</label>
        <input
          id="name"
          type="text"
          v-bind="name"
        >
      </div>
      <div>
        <label for="message">メッセージ</label>
        <textarea
          id="message"
          rows="6"
          v-bind="message"
        />
      </div>
      <div class="one-row">
        <input
          id="agree"
          type="checkbox"
          v-bind="agreeToPersonalInfo"
        >
        <label for="agree"><a
          href="/#/concept"
          target="_blank"
        >個人情報の取扱いに同意する</a></label>
      </div>
      <div class="action">
        <button
          class="cta"
          type="submit"
        >
          送信する
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'LpContact',
  data() {
    return {
      email: '',
      tel: '',
      name: '',
      message: '',
    };
  },
  methods: {
    send() {
      console.log("Sending data: ", this.data)
    }
  }
}
</script>
<style lang="sass" scoped>
.content
  background-color: #e5e5e5
  padding: 1rem
  form
    display: grid
    gap: 1rem
    max-width: 56rem
    justify-content: center
    margin: 0 auto
    padding: 1rem
    & > div
      display: grid
      gap: 0.25rem
      grid-template-columns: 1fr
      width: 20rem
      &.one-row
        grid-template-columns: max-content
        grid-auto-flow: column
        align-items: center
    .cta
      background-color: #b2282b
      color: #fff
      font-weight: bold
      border-radius: 3rem
      padding: 0.5rem 1rem
      border: none
      cursor: pointer
      &:hover
        background-color:	hsl(359, 63%, 33%)
    input,
    textarea
      border-radius: 4px
      border: none
      padding: 0.25rem
    label a
      color: black
      &:hover
        color:	hsl(359, 63%, 33%)
</style>
