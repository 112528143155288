<template>
  <div class="content">
    <h1>高度な電子デバイスの設計、開発と製造のサービスを届ける</h1>
    <a
      class="cta"
      href="#"
      @click.prevent="$emit('scroll-to', 'contact')"
    >お問い合わせ</a>
  </div>
</template>

<script>
export default {
  name: 'LpHome',
}
</script>
<style lang="sass" scoped>
.content
  height: calc(100vh - 100px)
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('~@/assets/images/devices.jpg')
  background-position: center
  background-repeat: no-repeat
  background-size: cover
  color: #fff
  display: flex
  flex-flow: column
  justify-content: center
  padding: 1rem
.cta
  display: inline-block
  background-color: #b2282b
  color: #fff
  font-weight: bold
  border-radius: 3rem
  padding: 0.5rem 1rem
  text-decoration: none
  align-self: center
  margin-top: 60px
  width: 20rem
  text-align: center
  &:hover
    background-color:	hsl(359, 63%, 33%)
</style>
