
import { defineComponent } from 'vue';
import { Temporal } from '@js-temporal/polyfill';

export default defineComponent({
  name: 'HomeView',
  data() {
    return {
      residence: "Tokyo, Japan",
      freelance: "Unavailable",
      ename: "joakim",
      domain: "flogaard.com",
    }
  },
  computed: {
    age() : number {
      const birthDate = Temporal.PlainDate.from("1987-11-19");
      const today = Temporal.Now.plainDateISO();
      console.log(today.since(birthDate))
      return Math.floor(today.since(birthDate).days / 365);
    },
    email() : string {
      return this.ename + "@" + this.domain;
    }
  }
});
