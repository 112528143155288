
import { defineComponent } from "vue";
import defaultLayout from "@/components/layouts/default.vue";
import conceptLayout from "@/components/layouts/concept.vue";

export default defineComponent({
  components: {
    defaultLayout,
    conceptLayout,
  },
  computed: {
    layout() {
      return this.$route.meta.layout || "defaultLayout";
    },
  },
});
