<template>
  <div class="default-layout">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'DefaultLayout',
}
</script>
<style lang="sass" scoped>
.default-layout
  background-color: var(--clr-base-lt)
  display: grid
  justify-content: center
  align-items: center
  padding: 1rem
  min-height: 100vh
  min-width: 100vv

@include for-size(portrait)
  .default-layout
    align-items: start
</style>
