<template>
  <div class="content">
    <h1>Access</h1>
    <div class="map-container">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d202.61917909195125!2d139.75464127851686!3d35.654666194896556!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188bf66985525b%3A0x1dcfd0065874e5fc!2z6YGU5oiQ6Zu75Zmo5qCq5byP5Lya56S-IFRhc3NlaSBEZW5raSBLSw!5e0!3m2!1sen!2sjp!4v1654136467303!5m2!1sen!2sjp" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
  </div>
</template>

    
<script>

export default {
  name: 'LpAccess',
}
</script>
<style lang="sass" scoped>
.content
  min-height: 100vh
  padding: 1rem
  .map-container
    text-align: center
    padding: 1rem
    iframe
      width: 100%
</style>
