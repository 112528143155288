<template>
  <div class="content">
    <h1>製品</h1>
    <div class="slides">
      <a
        href="#"
        class="back-button"
        :class="{ showBackButton: !showCards }"
        @click.prevent="showSlide('default')"
      >
        🔙
      </a>
      <div
        class="slide cards"
        :class="{ active: showCards }"
      >
        <div class="card">
          <a
            href="#"
            @click.prevent="showSlide('simHid')"
          >
            <div class="img-container">
              <img
                src="@/assets/images/sim-hid-icon.jpg"
                alt="シミュレーションHID"
              />
            </div>
            <h6>シミュレーションHID</h6>
            <button class="cta">製品を見る</button>
          </a>
        </div>
        <div class="card">
          <a
            href="#"
            @click.prevent="showSlide('simHid')"
          >
            <div class="img-container">
              <img
                src="@/assets/images/sim-hid-icon.jpg"
                alt="シミュレーションHID"
              />
            </div>
            <h6>シミュレーションHID</h6>
            <button class="cta">製品を見る</button>
          </a>
        </div>
        <div class="card">
          <a
            href="#"
            @click.prevent="showSlide('simHid')"
          >
            <div class="img-container">
              <img
                src="@/assets/images/sim-hid-icon.jpg"
                alt="シミュレーションHID"
              />
            </div>
            <h6>シミュレーションHID</h6>
            <button class="cta">製品を見る</button>
          </a>
        </div>
        <div class="card">
          <a
            href="#"
            @click.prevent="showSlide('simHid')"
          >
            <div class="img-container">
              <img
                src="@/assets/images/sim-hid-icon.jpg"
                alt="シミュレーションHID"
              />
            </div>
            <h6>シミュレーションHID</h6>
            <button class="cta">製品を見る</button>
          </a>
        </div>
        <div class="card">
          <a
            href="#"
            @click.prevent="showSlide('simHid')"
          >
            <div class="img-container">
              <img
                src="@/assets/images/sim-hid-icon.jpg"
                alt="シミュレーションHID"
              />
            </div>
            <h6>シミュレーションHID</h6>
            <button class="cta">製品を見る</button>
          </a>
        </div>
        <div class="card">
          <a
            href="#"
            @click.prevent="showSlide('simHid')"
          >
            <div class="img-container">
              <img
                src="@/assets/images/sim-hid-icon.jpg"
                alt="シミュレーションHID"
              />
            </div>
            <h6>シミュレーションHID</h6>
            <button class="cta">製品を見る</button>
          </a>
        </div>
      </div>
      <div
        class="slide details"
        :class="{ active: showSimHid }"
      >
        <div class="item-details">
          <div class="item-profile">
            <span class="tag tag--license">ライセンス商品</span>
            <span class="tag tag--xbox-s">MS Xbox S</span>
            <h6>レーシングホイールセット</h6>
            <div class="img-container">
              <img
                src="@/assets/images/ms-xbox-s-racing-set.jpg"
                alt="MS Xbox Sレーシングホイールセット"
              />
            </div>
          </div>
          <div class="item-description">
            <ul>
              <li>動作モード：有線/無線 （セキュリティIC）</li>
              <li>対象ブランド：Microsoft/PC、公式/ライセンスアクセサリー</li>
              <li>ペダル、ギアスティック、ホイール</li>
              <li>Type-c /ワイヤレス通信インターフェース</li>
              <li>フォースフィードバック、ダイレクト/ベルトドライブ</li>
              <li>ステレオヘッドセットサブシステム</li>
              <li>USB-IFおよびD4Xのテスト手順に関する苦情</li>
              <li>プレミアム表面仕上げ</li>
            </ul>
          </div>
        </div>
        <div class="item-details">
          <div class="item-profile">
            <span class="tag tag--license">ライセンス商品</span>
            <span class="tag tag--nintedo-switch">Nintendo Switch</span>
            <h6>レーシングホイール（テーブルマウント式）</h6>
            <div class="img-container">
              <img
                src="@/assets/images/nintendo-wheel.jpg"
                alt="レーシングホイール（テーブルマウント式）"
              />
            </div>
          </div>
          <div class="item-description">
            <ul>
              <li>動作モード：有線/無線 （セキュリティIC）</li>
              <li>対象ブランド：任天堂、公式/ライセンスアクセサリー</li>
              <li>正確な動きの制御</li>
              <li>タイプAバンドルケーブル（2 m）</li>
              <li>USB-IFおよびNSのテスト手順に関する苦情</li>
              <li>ウォータープリントテクノロジー（フロントピース）</li>
              <li>カラーボックス包装</li>
            </ul>
          </div>
        </div>
        <div class="item-details">
          <div class="item-profile">
            <span class="tag tag--license">ライセンス商品</span>
            <span class="tag tag--xbox-s">MS Xbox S</span>
            <h6>フライトスティック＋スロットルセット</h6>
            <div class="img-container">
              <img
                src="@/assets/images/flight-yoke.jpg"
                alt="フライトスティック＋スロットルセット"
              />
            </div>
          </div>
          <div class="item-description">
            <ul>
              <li>動作モード：有線コントローラー（セキュリティIC）</li>
              <li>対象ブランド：マイクロソフト社製、正規品/ライセンス品</li>
              <li>分離可能なヨークと制御象限</li>
              <li>タイプCコネクタ</li>
              <li>複数のアナログレバーとボタン</li>
              <li>ステレオヘッドセットサブシステム</li>
              <li>USB-IFおよびD4Xのテスト手順に関する苦情</li>
              <li>RGB LEDの洗練されたフィードバックシステム</li>
            </ul>
          </div>
        </div>
        <div class="item-details">
          <div class="item-profile">
            <span class="tag tag--license">ライセンス商品</span>
            <span class="tag tag--sony">ソニー</span>
            <h6>戦闘機＋スロットルセット</h6>
            <div class="img-container">
              <img
                src="@/assets/images/flight-stick.jpg"
                alt="戦闘機＋スロットルセット"
              />
            </div>
          </div>
          <div class="item-description">
            <ul>
              <li>動作モード：有線コントローラー（セキュリティIC）</li>
              <li>対象ブランド：ソニー、公式/ライセンスアクセサリー</li>
              <li>ジョイスティックとスロットルコントロール</li>
              <li>タイプCコネクタ</li>
              <li>プログラム可能なボタン機能</li>
              <li>フィードバック機能を強制する</li>
              <li>USB-IFおよびMGR9.1のテスト手順に関する苦情</li>
              <li>プレミアム品質の製品</li>
            </ul>
          </div>
        </div>
        <div class="item-details">
          <div class="item-profile">
            <span class="tag tag--license">ライセンス商品</span>
            <span class="tag tag--nintedo-switch">任天度</span>
            <h6>Switch Joy-Conホイールアクセサリー</h6>
            <div class="img-container">
              <img
                src="@/assets/images/joycon-accessory.jpg"
                alt="Switch Joy-Conホイールアクセサリー"
              />
            </div>
          </div>
          <div class="item-description">
            <ul>
              <li>内部に電子部品はありません</li>
              <li>対象ブランド：任天堂、公式/ライセンスアクセサリー</li>
              <li>任天堂の公式Joy-conと連携</li>
              <li>プレミアム表面仕上げ</li>
              <li>複数の色で利用可能</li>
              <li>NFTとAmiiboの機能を搭載可能</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LpProducts',
  data() {
    return {
      showCards: true,
      showSimHid: false,
    };
  },
  methods: {
    showSlide(slide) {
      this.showCards = false;
      this.showSimHid = slide == "simHid";
      
      if (!this.showSimHid) {
        this.showCards = true;
      }
    }
  },
}
</script>
<style lang="sass" scoped>
.content
  background-color: #e5e5e5
  padding: 1rem 1rem 1.5rem 1rem
  overflow: hidden
  .cards
    display: grid
    grid-template-columns: repeat(3, max-content)
    gap: 1rem
    justify-content: center
  .back-button
    opacity: 0
    pointer-events: none
    margin: 1rem 0
    display: inline-block
    text-decoration: none
  .showBackButton
    opacity: 1
    pointer-events: all
  .slide
    max-height: 0vh
    transform: translateX(-250%)
    transition: max-height 1s, transform cubic-bezier(0.65, 0.05, 0.36, 1) 1s
    &.active
      max-height: 200vh
      transform: translateX(0px)
    .card
      display: inline-block
      text-align: center
      background-color: #fff
      border-radius: 4px
      box-shadow: 0px 0px 9px -6px #000000, 0px 0px 0px 0px rgb(0 0 0 / 0%)
      a
        text-decoration: none
        color: #000
        padding: 1rem
        display: block
      &:hover .img-container
        border: 3px solid #b2282b
      &:hover .cta
        background-color:	hsl(359, 63%, 33%)
      .img-container
        width: 175px
        height: 175px
      h6
        padding: 1rem 0
  .cta
    background-color: #000
    color: #fff
    font-weight: bold
    border-radius: 3rem
    padding: 0.5rem 1rem
    border: none
    cursor: pointer
    &:hover
      background-color:	hsl(359, 63%, 33%)

  .img-container
    overflow: hidden
    border-radius: 50%
    border: 3px solid #000
    display: flex
    justify-content: center
    align-items: center
    img
      width: 100%
  .slide.details
    display: grid
    gap: 1rem
    grid-template-columns: repeat(2, 1fr)
  .item-details
    display: grid
    grid-template-columns: 14.5rem max-content
    gap: 2rem
    align-items: center
    background-color: #fff
    box-shadow: 0px 0px 9px -6px #000000, 0px 0px 0px 0px rgb(0 0 0 / 0%)
    border-radius: 4px
    padding: 1rem
    .item-profile
      h6
        margin: 1rem 0
        padding: 0
    .tag
      display: inline-block
      font-weight: bold
      padding: 0.25em 1em
      border-radius: 30px
      margin-right: 1rem
      font-size: 0.635rem
      &--license
        background-color: hsl(359, 63%, 33%)
        color: #fff
      &--xbox-s
        background-color: #107C10
        color: #fff
      &--nintedo-switch
        background-color: #e4000f
        color: #fff
      &--sony
        background-color: #006fcd
        color: #fff
    .img-container
      height: 200px
      width: 200px
    ul
      list-style: none
      padding-left: 1em
      li::before
        content: "\2022"
        color: #b2282b
        font-weight: bold
        display: inline-block
        width: 1em
        margin-left: -1em
</style>
