<template>
  <div class="content">
    <h1>会社概要</h1>
    <div>
      <dl>
        <dt>社名</dt>
        <dd>達成電器株式会社</dd>
    
        <dt>本社</dt>
        <dd>東京都港区浜松町二丁目2番11号廣瀬ビル4階</dd>

        <dt>創業</dt>
        <dd>(中国）2007 設立 ( 日本): 2021</dd>

        <dt>資本金</dt>
        <dd>500万円</dd>

        <dt>代表取締役</dt>
        <dd>アンドレイ ゼレツノフ</dd>

        <dt>従業員数</dt>
        <dd>4 (東京都）</dd>

        <dt>業務内容</dt>
        <dd>サービス貿易会社（子会社）</dd>
      
        <dt>取引銀行</dt>
        <dd>三菱東京ＵＦＪ銀行田町支店</dd>
      
        <dt>関連会社</dt>
        <dd>Dashine Electronics Co. Ltd (親会社）</dd>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LpCompanyDetails',
}
</script>
<style lang="sass" scoped>
.content
  padding: 1rem
  background-color: #FFFCF9
  & > div
    padding: 1rem 0
  dl
    margin: 0
    display: grid
    grid-template-columns: repeat(2, max-content)
    gap: 1rem
    justify-content: center
    dt
      text-align: right
      color: #b2282b
</style>
