import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ConceptView from '../views/ConceptView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/concept',
    name: 'concept',
    component: ConceptView,
    meta: {
      layout: 'conceptLayout',
    }
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
