<template>
  <main ref="main">
    <header>
      <div id="logo-bar">
        <a href="#"><span>達成</span><span>電気</span></a>
      </div>
      <nav>
        <a
          v-for="section in sections"
          :key="section.id"
          href="#"
          @click.prevent="scrollTo(section.id)"
        >{{ section.label }}</a>
      </nav>
    </header>
    <section
      v-for="section in sections"
      :ref="section.id"
      :key="section.id"
    >
      <component
        :is="section.component"
        @scroll-to="scrollTo"
      />
    </section>
    <footer>
      <nav>
        <div>
          <h4>
            サービス
          </h4>
          <a href="/service1">Service 1</a>
          <a href="/service2">Service 2</a>
          <a href="/service3">Service 3</a>
          <a href="/service4">Service 4</a>
          <a href="/service5">Service 5</a>
          <a href="/service6">Service 6</a>
        </div>
        <div>
          <h4>
            キャリア
          </h4>
          <a href="/positions">採用情報</a>
        </div>
      </nav>
      <div id="copyright">
        copyright text here 2022
      </div>
    </footer>
  </main>
</template>
<script>
import LpHome from '@/components/lp/home.vue';
import LpAbout from  '@/components/lp/about.vue';
import LpProducts from  '@/components/lp/products.vue';
import LpServices from  '@/components/lp/services.vue';
import LpManufacturing from  '@/components/lp/manufacturing.vue';
import LpCompanyDetails from  '@/components/lp/companyDetails.vue';
import LpContact from  '@/components/lp/contact.vue';
import LpAccess from  '@/components/lp/access.vue';

export default {
  components: {
    LpHome,
    LpAbout,
    LpProducts,
    LpServices,
    LpManufacturing,
    LpCompanyDetails,
    LpContact,
    LpAccess,
  },
  data() {
    return {
      sections: [
        {
          id: "home",
          label: "Home",
          href: "#home",
          component: "LpHome",
        },
        {
          id: "about",
          label: "達成電気について",
          href: "#about",
          
          component: "LpAbout",
        },
        {
          id: "products",
          label: "製品",
          href: "#products",
          component: "LpProducts",
        },
        {
          id: "services",
          label: "サービス",
          href: "#services",
          component: "LpServices",
        },
        {
          id: "manufacturing",
          label: "製造機能",
          href: "#manufacturing",
          component: "LpManufacturing",
        },
        {
          id: "company-details",
          label: "会社概要",
          href: "#company-details",
          component: "LpCompanyDetails",
        },
        {
          id: "contact",
          label: "お問い合わせ",
          href: "#contact",
          component: "LpContact",
        },
        {
          id: "access",
          label: "アクセス",
          href: "#access",
          component: "LpAccess",
        },
      ]
    };
  },
  methods: {
    scrollTo(refName) {
      var element = this.$refs[refName];
      var top = element[0].offsetTop;
      this.$refs['main'].scrollTo({
        top: top,
        behavior: "smooth",
      });
    },
  },
}
</script>
<style lang="sass" scoped>
main
  font-family: Noto Sans JP,Hiragino Sans,ヒラギノ角ゴシック,-apple-system,Meiryo,sans-serif
  height: 100vh
  width: 100vw
  overflow-y: scroll
  scroll-snap-type: y proximity
header
  scroll-snap-align: start
section
  scroll-snap-align: start
footer
  scroll-snap-align: end
  background-color: #232020
  color: #ffffff
  padding: 1rem
  nav
    padding: 0
    display: grid
    gap: 5rem
    > div
      display: grid
      grid-auto-flow: row
      gap: 0.5rem
      align-content: start
    a
      color: #fff
      &:hover
        color: hsl(359, 63%, 66%)
        text-decoration: none
        border: none
  #copyright
    font-size: 0.8rem
    margin-top: 2rem
    padding-top: 1rem
    border-top: 1px solid #FFFCF9
#logo-bar
  overflow: hidden
  padding: 0.5rem 0
  a
    position: relative
    text-decoration: none
    color: #000
    padding: 1rem
    font-size: 24px
    cursor: pointer
    span
      z-index: 3
    span:last-child
      color: #fff
    &::before,
    &::after
      content: ''
      display: block
      position: absolute
      height: 100%
    &::before
      background-color: #fff
      left: -294px
      transform: rotate(-52deg)
      z-index: -1
      width: 452px
      height: 200%
    &::after
      background-color: #b2282b
      top: 0
      width: 100vw
      z-index: -2
nav
  display: grid
  grid-template-columns: repeat(8, max-content)
  gap: 1rem
  justify-content: start
  justify-items: center
  padding: 1rem
  a
    color: #000
    text-decoration: none
    border-bottom: 1px solid #000
    box-sizing: border-box
    &:hover
      color: #b2282b
      border-bottom-color: #b2282b
      
</style>
