<template>
  <div class="content">
    <h1>達成電気について</h1>
    <article>
      <header>
        <h4>達成電気株式会社</h4>
      </header>
      <div class="article-content">
        <div>
          <p>
            達成電器株式会社はDashineグループの子会社として2022年に設立しました。
            日本に事務所を構え、経営理念に基づいてサービスを行なっております。
          </p>
        </div>
        <div class="img-container">
          <img
            src="@/assets/images/about-tasseidenki.jpg"
            alt="オフィス"
          >
        </div>
      </div>
    </article>
    <article>
      <header>
        <h4>Dashineグループ</h4>
      </header>
      <div class="article-content">
        <div class="img-container">
          <img
            src="@/assets/images/dashine-office-locations.png"
            alt="Dashineオフィスの地図"
          >
        </div>
        <div>
          <p>
            Dashine は、IoT、ドローン、ゲーム、おもちゃ、VR / ARギアなど、 高度な電子デバイスの設計、開発、製造を専門とするグローバルグループです。
          </p>
          <p>
            2006年に設立されたDashineグループは、現在、上場企業として 成功を収めています。
          </p>
          <p>
            外部に一切委託することなく、ほぼすべての電子機器の設計、 開発、製造を適正な製造基準で完全に行うことができます。 そのため、大規模工場に期待されるような優れた品質と優れた サービスを保証致します。しかし、同時に、（多くのスーパー工 場のように）過度に大規模ではないので、より速く、より柔軟に、 より安く対応することができます。
          </p>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: 'LpAbout',
}
</script>
<style lang="sass" scoped>
.content
  min-height: 100vh
  padding: 1rem
  background-color: #FFFCF9
  article
    padding: 1rem 0
    max-width: 56rem
    margin: 0 auto
    .article-content
      display: grid
      grid-template-columns: repeat(2, 1fr)
      gap: 1rem
      margin-top: 1rem
      p
        margin-bottom: 1rem
    .img-container
      img
        width: 100%
</style>
